import { BasicUserInfoResponseType } from "../../../api";
import { URL_LOGOUT, URL_WKIS_STATE_API, getLink } from "../constants/urls";

const sessionTimoutInSeconds = 3570;
const pollInterval = 10;

export class SessionTimer {
  initialTimeoutInSeconds: number;
  timeoutInSeconds: number;
  sessionTimer: any;
  sessionUserInfo: BasicUserInfoResponseType | undefined;

  constructor(userInfo: BasicUserInfoResponseType) {
    this.sessionUserInfo = userInfo;
    this.initialTimeoutInSeconds = sessionTimoutInSeconds;
    this.timeoutInSeconds = sessionTimoutInSeconds;
    this.sessionTimer = 0;
  }

  decrementCounter(): void {
    if (this.timeoutInSeconds <= 0) {
      clearInterval(this.sessionTimer);
      return;
    }
    this.checkCookie();
    this.timeoutInSeconds -= pollInterval;
  }

  startCounter(): void {
    clearInterval(this.sessionTimer);
    this.sessionTimer = 0;
    this.decrementCounter();
    this.sessionTimer = setInterval(() => {
      this.decrementCounter();
    }, pollInterval * 1000);
  }

  resetTimer(): void {
    this.timeoutInSeconds = sessionTimoutInSeconds;
  }

  checkCookie(): void {
    var urlParams = new URLSearchParams(window.location.search);
    var debug = urlParams.get("debug");
    if (this.sessionUserInfo && window.location.protocol.indexOf("https") >= 0 && !debug) {
      checkWKISState()
        .then((response) => response.json())
        .then((res) => {
          if (!res.signedIn) {
            window.location.href = getLink(URL_LOGOUT);
          }
        })
        .catch(() => {
          window.location.href = getLink(URL_LOGOUT);
        });
    }
  }
}

export const checkWKISState = () => {
  return fetch(getLink(URL_WKIS_STATE_API), {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    mode: "cors",
  });
};
